import React from "react";

/**
 * Loading spinner shown while the app fetches data.
 *
 * @returns {React.ReactNode} Component virtual node instance
 */
const PolicyAgreementAppLoading = () => {
  return (
      <div data-testid="policyAgreementAppLoading" className="policyAgreementAppLoading">
        <div className="lds-ring"><div /><div /><div /><div /></div>
      </div>
  );
};

export default PolicyAgreementAppLoading;
