import AuthClientConfig from "./AuthClientConfig";
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

/**
 * Gets the authentication client with configuration based on location.
 *
 * @param {AuthClientConfig} config Auth client configuration object
 * @param {string} url Full url of the current page
 * @param {OktaAuthOptions} options Okta auth options, defaults to storage as "localStorage"
 * @returns {OktaAuth} Authentication client
 */
function getAuthClient(
    config: AuthClientConfig,
    url: string,
    options: OktaAuthOptions = { tokenManager: { storage: "localStorage" } }
): OktaAuth {
  const querylessUrl = new URL(url);
  querylessUrl.search = '';

  return new OktaAuth({
    ...config,
    redirectUri: querylessUrl.href,
    pkce: true,
    ...options,
  });
}

export default getAuthClient;
