import { createContext } from "react";
import defaultLangcode from "./defaultLangcode";

/**
 * User context with current user details.
 */
const UserContext = createContext<{userLangcode: string}>({
  userLangcode: defaultLangcode,
});

export default UserContext;