import {useContext} from "react";
import defaultLangcode from "./defaultLangcode";
import i18nMessageContext from "./i18nMessageContext";
import UserContext from "./UserContext";

/**
 * Hook that returns a translator function for internationalizing messages.
 * Translator function is given a message identifier and returns a
 * translation based on the user's language, or the default language.
 *
 * @returns {function(messageId: string): string} the translator function
 */
const useI18nTranslator = () => {
  const { userLangcode } = useContext(UserContext);
  const messages = useContext(i18nMessageContext);

  return (messageId: string) => {
    let langcode = userLangcode;

    if (!messages?.[langcode]?.[messageId]) {
      langcode = defaultLangcode;
    }

    if (!messages?.[langcode]?.[messageId]) {
      throw new Error(`Failed to translate message '${messageId}' and there is no default`);
    }

    return messages[langcode][messageId];
  };
};

export default useI18nTranslator;
