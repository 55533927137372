import React, { useCallback, useContext } from "react";
import Accordion from "./Accordion";
import defaultLangcode from "./defaultLangcode";
import {
  MESSAGE_AGREE_CONTINUE,
  MESSAGE_AGREEMENTS_UP_TO_DATE,
  MESSAGE_CANCEL, MESSAGE_CONTINUE
} from "./i18nMessageContext";
import Policy from "./Policy";
import PolicyAgreement from "./PolicyAgreement";
import PolicyAgreementFrame from "./PolicyAgreementFrame";
import useI18nTranslator from "./useI18nTranslator";
import UserContext from "./UserContext";

/**
 * Policy agreement form component props.
 */
interface PolicyAgreementFormProps {
  location: Location;
  appId: string;
  destination: string;
  policyAgreements: PolicyAgreement[];
  policies: Policy[];
  onSubmit: (e: React.SyntheticEvent) => void;
  onCancel: (e: React.SyntheticEvent) => void;
}

/**
 * Lists given policies and wraps them in a form for the user to read through.
 *
 * @param {PolicyAgreementFormProps} props Component props
 * @returns {React.ReactNode} Component virtual node instance
 */
const PolicyAgreementForm = (props: PolicyAgreementFormProps) => {
  const { location, appId, destination, policyAgreements, policies, onSubmit, onCancel } = props;
  const { userLangcode } = useContext(UserContext);
  const t = useI18nTranslator();

  const policiesById = policies.reduce((byId: { [key: string]: Policy}, policy) => {
    byId[policy.id] = policy;
    return byId;
  }, {});

  const getItemTitleCallback = useCallback((item: React.ReactElement) => {
    const policyId = item.props?.policy?.id;

    if (!policyId) {
      throw new Error("React element has no policy prop with id property");
    }

    const policy = policiesById[policyId];
    const translation = policy.translations[userLangcode] || policy.translations[defaultLangcode];

    return translation.title;
  }, [
    policiesById,
    // To ignore warning about complex expression for policiesById.
    // policiesById is stringified for easy deep comparisons.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(policiesById),
    userLangcode
  ]);

  const actionParams = new URLSearchParams({
    appId,
    destination
  });

  const action = new URL(`redirect?${actionParams}`, location.href).toString();

  return (
      <form data-testid="policyAgreementForm" className="policyAgreementForm" method="get" action={action} onSubmit={onSubmit} autoComplete="off">
        {policyAgreements.length > 0 ?
          <Accordion getItemTitleCallback={getItemTitleCallback}>
            ${policyAgreements.map(agreement => (
              <PolicyAgreementFrame
                  key={agreement.policyId}
                  policy={policiesById[agreement.policyId]}
              />
            ))}
          </Accordion>
          :
          <div>{t(MESSAGE_AGREEMENTS_UP_TO_DATE)}</div>
        }
        <div className="policyAgreementActions">
          <button data-testid="cancel-button" className="button button-secondary" name="cancel" value="1" onClick={onCancel}>
            {t(MESSAGE_CANCEL)}
          </button>
          <button data-testid="submit-button" className="button button-primary" name="submit" value="1" type="submit">
            {t(policyAgreements.length > 0 ? MESSAGE_AGREE_CONTINUE : MESSAGE_CONTINUE)}
          </button>
        </div>
      </form>
  );
};

export default PolicyAgreementForm;
