import React, { useContext } from "react";
import defaultLangcode from "./defaultLangcode";
import Policy from "./Policy";
import UserContext from "./UserContext";

/**
 * Policy agreement frame component props.
 */
interface PolicyAgreementFrameProps {
  policy: Policy
}

/**
 * Displays a given policy document in an iframe.
 *
 * @param {PolicyAgreementFrameProps} props Component props
 * @returns {React.ReactNode} Component virtual node instance
 */
const PolicyAgreementFrame = (props: PolicyAgreementFrameProps) => {
  const { policy, ...frameProps } = props;
  const { userLangcode } = useContext(UserContext);

  let langcode = userLangcode;

  if (!policy.translations[langcode]) {
    langcode = defaultLangcode
  }

  return (
      <iframe
          className="policyAgreementFrame"
          title={policy.translations[langcode].title}
          src={policy.translations[langcode].documentUri}
          {...frameProps}
      />
  );
};

export default PolicyAgreementFrame;
