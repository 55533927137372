import React, { useContext } from "react";
import { EnvContext, LINK_GFSCA, LINK_GFSCOM, LINK_SUPPLIER } from "./EnvConstants";
import {
  MESSAGE_BACK_TO_GFSCA,
  MESSAGE_BACK_TO_GFSCOM,
  MESSAGE_BACK_TO_SUPPLIER
} from "./i18nMessageContext";
import useI18nTranslator from "./useI18nTranslator";

/**
 * Content shown when the policy agreements flow is canceled by the user.
 * Presents the user with top level locations to navigate back to.
 *
 * @returns {React.ReactNode} Component virtual node instance
 */
const PolicyAgreementAppCanceled = () => {
  const t = useI18nTranslator();
  const env = useContext(EnvContext);
  const l = env.links;

  return (
      <div data-testid="policyAgreementAppCanceled" className="policyAgreementAppCanceled">

        <a id="us-link" href={l[LINK_GFSCOM]} className="button button-primary">
          <svg className="flag" width="19" height="14" viewBox="0 0 19 14">
            <g fill="none" fillRule="nonzero">
              <path fill="#EEE" d="M.104 12.358a2.272 2.272 0 0 0 .223.56l.001.002c.365.64 1.035 1.076 1.812 1.076h14.72c.777 0 1.447-.436 1.811-1.076l.002-.003a2.298 2.298 0 0 0 .29-1.065V2.157c0-.126-.015-.247-.036-.366a1.99 1.99 0 0 0-.08-.31 2.254 2.254 0 0 0-.176-.4C18.307.44 17.637.003 16.861.003H9.5V7.538H.037v4.305a2.343 2.343 0 0 0 .067.514z" />
              <g fill="#B22334">
                <path d="M18.671 1.08C18.307.44 17.637.004 16.861.004H9.5V1.08h9.171zM.037 11.843h18.926v-1.076H.037zM9.5 6.462h9.463v1.076H9.5zM9.5 4.309h9.463v1.076H9.5zM.037 8.614h18.926V9.69H.037zM16.86 13.996c.777 0 1.447-.436 1.811-1.076H.33c.364.64 1.034 1.076 1.81 1.076H16.86zM9.5 2.157h9.463v1.076H9.5z" />
              </g>
              <g fill="#EEE">
                <path d="M.099 2.157c0-.126.085-.246.193-.366-.113.12-.193.24-.193.366M.917 1.11c-.002.002-.005.004-.005.006.003-.002.003-.004.005-.007zM.274 1.643c.054-.055.13-.108.2-.16-.072.052-.143.105-.2.16" />
              </g>
              <path fill="#3C3B6E" d="M9.5.004H2.14C.979.004.037.968.037 2.157v5.381H9.5V.004z" />
              <g fill="#FFF">
                <path d="M1.09 1.47l.324.242-.124.391.324-.241.325.241-.124-.39.325-.242h-.402l-.124-.39-.123.39zM2.14 2.547l.325.242-.124.39.325-.241.325.241-.125-.39.325-.242H2.79l-.124-.39-.124.39zM4.244 2.547l.324.242-.124.39.325-.241.325.241-.125-.39.325-.242h-.401l-.124-.39-.124.39zM6.346 2.547l.325.242-.124.39.325-.241.324.241-.124-.39.325-.242h-.401l-.124-.39-.124.39zM2.14 4.7l.325.241-.124.391.325-.242.325.242-.125-.39.325-.242H2.79l-.124-.39-.124.39zM4.244 4.7l.324.241-.124.391.325-.242.325.242-.125-.39.325-.242h-.401l-.124-.39-.124.39zM6.346 4.7l.325.241-.124.391.325-.242.324.242-.124-.39.325-.242h-.401l-.124-.39-.124.39zM3.192 1.47l.325.242-.125.391.325-.241.325.241-.125-.39.326-.242H3.84l-.124-.39-.123.39zM5.295 1.47l.325.242-.125.391.325-.241.325.241-.125-.39.326-.242h-.402l-.124-.39-.123.39zM7.398 1.47l.325.242-.125.391.325-.241.325.241-.125-.39.326-.242h-.402l-.124-.39-.124.39zM1.09 3.624l.324.241-.124.39.324-.24.325.24-.124-.39.325-.241h-.402l-.124-.391-.123.39zM3.393 4.256l.324-.242.325.242-.124-.39.325-.243h-.401l-.125-.39-.124.39h-.4l.324.242zM5.295 3.624l.325.241-.125.39.325-.24.325.24-.125-.39.326-.241h-.402l-.124-.391-.123.39zM7.398 3.624l.325.241-.125.39.325-.24.325.24-.125-.39.326-.241h-.402l-.124-.391-.124.39zM1.09 5.776l.324.242-.124.39.324-.241.325.241-.124-.39.325-.242h-.402l-.124-.39-.123.39zM3.393 6.408l.324-.241.325.241-.124-.39.325-.242h-.401l-.125-.39-.124.39h-.4l.324.242zM5.295 5.776l.325.242-.125.39.325-.241.325.241-.125-.39.326-.242h-.402l-.124-.39-.123.39zM7.398 5.776l.325.242-.125.39.325-.241.325.241-.125-.39.326-.242h-.402l-.124-.39-.124.39z" />
              </g>
            </g>
          </svg>
          {t(MESSAGE_BACK_TO_GFSCOM)}
        </a>

        <a id="canada-link" href={l[LINK_GFSCA]} className="button button-primary">
          <svg className="flag" width="19" height="14" viewBox="0 0 19 14">
            <g fill="none" fillRule="nonzero">
              <g fill="#D52B1E">
                <path d="M2.107.042C.943.042 0 1 0 2.182v9.635c0 1.183.943 2.141 2.107 2.141h3.16V.042h-3.16zM16.856.042h-3.16v13.916h3.16c1.164 0 2.107-.958 2.107-2.14V2.182C18.963 1 18.02.042 16.856.042" />
              </g>
              <path fill="#EEE" d="M5.268 13.958h8.427V.042H5.267z" />
              <path fill="#D52B1E" d="M9.805 9.201c.631.075 1.197.142 1.828.215l-.16-.536a.248.248 0 0 1 .083-.254l1.832-1.517-.379-.182c-.167-.06-.12-.156-.06-.386l.28-1.036-1.065.228a.163.163 0 0 1-.19-.116l-.136-.487-.842.96c-.12.154-.362.154-.287-.201l.36-1.946-.482.254c-.136.077-.271.09-.347-.047l-.666-1.267v.032-.032L8.907 4.15c-.075.137-.21.124-.346.047l-.482-.254.36 1.946c.075.355-.168.355-.288.201l-.841-.96-.137.487a.164.164 0 0 1-.19.116L5.92 5.505 6.2 6.54c.059.23.105.326-.062.386l-.379.182 1.833 1.517c.072.057.109.16.083.254l-.16.536c.63-.073 1.196-.14 1.827-.215.056 0 .093.032.093.097l-.113 1.983h.504l-.113-1.983c0-.065.037-.097.092-.097" />
            </g>
          </svg>
          {t(MESSAGE_BACK_TO_GFSCA)}
        </a>

        <a id="supplier-link" href={l[LINK_SUPPLIER]} className="button button-primary">
          {t(MESSAGE_BACK_TO_SUPPLIER)}
        </a>
      </div>
  );
};

export default PolicyAgreementAppCanceled;
