import React from "react";
import PolicyAgreementAppError from "./PolicyAgreementAppError";

/**
 * Catches errors of component children.
 */
class PolicyAgreementErrorBoundary extends React.Component<React.PropsWithChildren<{}>, { hasError: boolean }> {

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    console.log("Failed to display policy agreements", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <PolicyAgreementAppError />;
    }

    return this.props.children;
  }
}

export default PolicyAgreementErrorBoundary;
