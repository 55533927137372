import React from "react";
import { MESSAGE_ERROR_BODY, MESSAGE_ERROR_TITLE } from "./i18nMessageContext";
import PolicyAgreementAppCanceled from "./PolicyAgreementAppCanceled";
import useI18nTranslator from "./useI18nTranslator";

/**
 * Friendly error message which is displayed if the app fails.
 *
 * @returns {React.ReactNode} Component virtual node instance
 */
const PolicyAgreementAppError = () => {
  const t = useI18nTranslator();

  return (
    <>
      <div data-testid="policyAgreementAppError" className="policyAgreementAppError">
        <h2>{t(MESSAGE_ERROR_TITLE)}</h2>
        {/* dangerouslySetInnerHTML needed for HTML links in translation. */}
        <p dangerouslySetInnerHTML={{ __html: t(MESSAGE_ERROR_BODY) }} />
      </div>
      <PolicyAgreementAppCanceled />
    </>
  );
};

export default PolicyAgreementAppError;
