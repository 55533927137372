import React, { useCallback, useState } from "react";
import AccordionItem from "./AccordionItem"

/**
 * Accordion props.
 */
interface AccordionProps {
  getItemTitleCallback?: (item: React.ReactElement, index: number) => string;
}

/**
 * Presents children in an accordion user interface. Children components are
 * wrapped in an {@link AccordionItem} component.
 *
 * @param {React.PropsWithChildren<AccordionProps>} props Component props
 * @returns {React.ReactNode} Component virtual node instance
 */
function Accordion(props: React.PropsWithChildren<AccordionProps>) {
  const { children, getItemTitleCallback } = props;
  const [itemsExpanded, setItemsExpanded] = useState<{[key: string]: boolean}>({});

  const handleHeaderClick = useCallback((itemId: string, e: React.MouseEvent) => {
    e.preventDefault();

    setItemsExpanded((items) => {
      // All items will be closed, so grab current value so it can be toggled.
      const prevValue = items[itemId];
      const updatedItemsExpanded = Object.assign({}, items);

      // Close any accordion items that are open.
      for (const id of Object.keys(updatedItemsExpanded)) {
        updatedItemsExpanded[id] = false;
      }

      updatedItemsExpanded[itemId] = prevValue !== undefined ?
          !prevValue :
          true;

      return updatedItemsExpanded;
    });
  }, []);

  return (
      <div className="accordion" data-testid="accordion">
        {React.Children.toArray(children)
        .filter((c): c is React.ReactElement => typeof c === 'object' && "key" in c && !!c.key)
        .map((c, i) =>
            <AccordionItem
                key={c.key!}
                expanded={itemsExpanded[c.key!]}
                itemId={c.key!.toString()}
                title={getItemTitleCallback?.(c, i) || ""}
                onHeaderClick={handleHeaderClick}
            >
              {c}
            </AccordionItem>
        )}
      </div>
  );
}

export default Accordion;
