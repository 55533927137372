import {createContext} from "react";
import defaultLangcode from "./defaultLangcode";

export const MESSAGE_CANCEL = 'MESSAGE_CANCEL';
export const MESSAGE_CONTINUE = 'CONTINUE';
export const MESSAGE_AGREE_CONTINUE = 'MESSAGE_AGREE_CONTINUE';
export const MESSAGE_ERROR_TITLE = 'MESSAGE_ERROR_TITLE';
export const MESSAGE_ERROR_BODY = 'MESSAGE_ERROR_BODY';
export const MESSAGE_AGREEMENTS_UP_TO_DATE = 'MESSAGE_AGREEMENTS_UP_TO_DATE';
export const MESSAGE_BACK_TO_GFSCA = 'MESSAGE_BACK_TO_GFSCA';
export const MESSAGE_BACK_TO_GFSCOM = 'MESSAGE_BACK_TO_GFSCOM';
export const MESSAGE_BACK_TO_SUPPLIER = 'MESSAGE_BACK_TO_SUPPLIER';

export const i18MessageMap: { [key: string]: {[key: string]: string}} = {
  [defaultLangcode]: {
    [MESSAGE_CANCEL]: "Cancel",
    [MESSAGE_CONTINUE]: "Continue",
    [MESSAGE_AGREE_CONTINUE]: "Agree and Continue",
    [MESSAGE_ERROR_TITLE]: "Oops! This is embarrassing.",
    [MESSAGE_ERROR_BODY]: "We are currently unable to display this page, " +
    "we apologize for the inconvenience. You can either try " +
    "reloading the page, come back later or " +
    '<a href="https://sites.google.com/public.gfs.com/contact-us/HomeAccueil">contact Customer Service</a>.',
    [MESSAGE_AGREEMENTS_UP_TO_DATE]: "Your policy agreements are up to date.",
    [MESSAGE_BACK_TO_GFSCA]: "Back to gfs.ca",
    [MESSAGE_BACK_TO_GFSCOM]: "Back to gfs.com",
    [MESSAGE_BACK_TO_SUPPLIER]: "Back to Supplier Central"
  },
  "en-ca": {
  },
  "fr-ca": {
    [MESSAGE_CANCEL]: "Annuler",
    [MESSAGE_CONTINUE]: "Continuer",
    [MESSAGE_AGREE_CONTINUE]: "Accepter et continuer",
    [MESSAGE_ERROR_TITLE]: "Oups! C'est gênant.",
    [MESSAGE_ERROR_BODY]: "Nous ne pouvons pas afficher cette page pour le moment, " +
    "nous nous excusons pour le désagrément. Vous pouvez soit essayer de recharger la page, " +
    "essayer de recharger la page, revenir plus tard ou soit revenir plus tard, soit " +
    '<a href="https://sites.google.com/public.gfs.com/contact-us/HomeAccueil">contacter le service à la clientèle</a>.',
    [MESSAGE_AGREEMENTS_UP_TO_DATE]: "Vos accords de police sont à jour.",
    [MESSAGE_BACK_TO_GFSCA]: "Retour à gfs.ca",
    [MESSAGE_BACK_TO_GFSCOM]: "Retour à gfs.com",
    [MESSAGE_BACK_TO_SUPPLIER]: "Retour à Portail fournisseur"
  }
};

/**
 * Message context with translation strings for i18n.
 */
const i18nMessageContext = createContext(i18MessageMap);

export default i18nMessageContext;
