// Polyfills MUST be first or React will fail on IE 11.
import "./polyfills";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { getEnvConstants } from "./app/EnvConstants";
import getAuthClient from "./app/getAuthClient";

const authClientConfig = getEnvConstants(window.location.hostname).authClient;

ReactDOM.render(
    <React.StrictMode>
      <App
          env={getEnvConstants(window.location.hostname)}
          location={window.location}
          history={window.history}
          userLangcode={navigator.language.toLowerCase()}
          authClient={getAuthClient(authClientConfig, window.location.href)}
      />
    </React.StrictMode>,
    document.getElementById('app')
);
