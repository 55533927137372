import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import fetchJson from "./fetchJson";
import isTokenJwt from "./isTokenJwt";
import PolicyAgreement from "./PolicyAgreement";
import PolicyAgreementAppCanceled from "./PolicyAgreementAppCanceled";
import PolicyAgreementAppError from "./PolicyAgreementAppError";
import PolicyAgreementAppLoading from "./PolicyAgreementAppLoading"
import PolicyAgreementErrorBoundary from "./PolicyAgreementErrorBoundary";
import PolicyAgreementForm from "./PolicyAgreementForm";
import { OktaAuth } from '@okta/okta-auth-js';
import Policy from "./Policy";
import { AuthFlowState } from "./useAuthTokens";

/**
 * Policy agreement app props.
 */
interface PolicyAgreementAppProps {
  authClient: OktaAuth;
  authFlowState: AuthFlowState;
  location: Location;
  appId: string | null;
  accessToken: string | null;
  destination: string | null;
  ssoEndpoint: string;
  policiesEndpoint: string;
  policyAgreementsEndpoint: string;
  policyAgreementsOutdatedEndpoint: string;
  defaultFetchOptions?: RequestInit;
}

/**
 * Application component. Loads policies and outdated policy agreements.
 * Handles PUTing updated policy agreements to the backend.
 * Manages overall loading and error states.
 *
 * @param {PolicyAgreementAppProps} props Component props
 * @returns {React.ReactNode} Component virtual node instance
 */
const PolicyAgreementApp = (props: PolicyAgreementAppProps) => {
  const {
    authClient,
    authFlowState,
    location,
    appId,
    accessToken,
    destination,
    ssoEndpoint,
    policiesEndpoint,
    policyAgreementsEndpoint,
    policyAgreementsOutdatedEndpoint,
    defaultFetchOptions
  } = props;

  const fetchOptions = useMemo(() => {
    const headers: HeadersInit = {
      'content-type': 'application/json'
    };

    if (accessToken) {
      if (isTokenJwt(accessToken)) {
        headers.authorization = `Bearer ${accessToken}`;
      } else {
        headers['x-okta-idx-state-handle'] = accessToken;
      }
    }

    return {
      ...defaultFetchOptions,
      headers: {
        ...(defaultFetchOptions ? defaultFetchOptions.headers : []),
        ...headers
      },
    };
  }, [accessToken, defaultFetchOptions]);

  const [errored, setErrored] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [outdatedAgreements, setOutdatedAgreements] = useState<PolicyAgreement[]>([]);

  const handleCancel = useCallback((e: React.SyntheticEvent) => {
    // Prevent any potential form submission.
    e.preventDefault();

    if (accessToken && isTokenJwt(accessToken)) {
      authClient.tokenManager.clear();

      fetch(ssoEndpoint + '/api/v1/sessions/me', {
        headers: {
          'accept': 'application/json',
        },
        method: 'DELETE',
        credentials: "include"
      }).catch(() => {
        // Do nothing if session removal fails.
      });
    }

    setCanceled(true);
  }, [ssoEndpoint, accessToken, authClient]);

  /** Form submission callback. Handles PUTing policy agreements. */
  const handleSubmit = useCallback(async (e: React.SyntheticEvent) => {
    // Form will be disconnected/removed when loading animation is shown,
    // so default submit behavior will be canceled, but ensure it doesn't
    // happen so we can handle it ourselves.
    e.preventDefault();

    const actionUrl = e.currentTarget.getAttribute('action')!;

    let failed = false;

    // Show loading animation while posting data and redirecting.
    setLoaded(false);

    if (outdatedAgreements.length > 0) {
      const putAgreements = fetchJson(policyAgreementsEndpoint + '/collection', {
        ...fetchOptions,
        method: "PUT",
        body: JSON.stringify(outdatedAgreements.map(a => ({
          ...a,
          agreement: true,
          // agreementDate is determined by the server.
          agreementDate: null
        })))
      });

      await putAgreements.catch((reason) => {
        console.error("Failed save updated policy agreements", reason);
        setErrored(true);
        failed = true;
      });
    }

    if (!failed && destination) {
      location.assign(actionUrl);
    }
  }, [
    destination,
    location,
    policyAgreementsEndpoint,
    outdatedAgreements,
    // To ignore warning about complex expression for outdatedAgreements.
    // outdatedAgreements is stringified for easy deep comparisons.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(outdatedAgreements),
    fetchOptions
  ]);

  // Validate that required props are set and correct. Set error state if not.
  useEffect(() => {
    // Don't check the destination while authentication is happening.
    // Otherwise, during OIDC flow, the app can fail early because the
    // destination value hasn't been retrieved from OIDC state yet.
    if (authFlowState !== AuthFlowState.COMPLETED) {
      return;
    }

    if (!destination) {
      console.error("Failed to display page", "`destination` query parameter not set");
      setErrored(true);
      return;
    }

    try {
      const decodedDestination = destination.startsWith("https://") ?
          destination :
          atob(destination
          .replace(/-/g, '+')
          .replace(/_/g, '/'));

      new URL(decodedDestination);
    } catch (e) {
      console.error("Failed to display page", "Failure during URL decoding", e);
      setErrored(true);
    }
  }, [
    accessToken,
    appId,
    authFlowState,
    destination,
    loaded
  ]);

  // Fetches policies and outdated policy agreements from the endpoints for
  // the current user.
  useEffect(() => {
    // Don't attempt to load data until an authentication token is available.
    // Otherwise, it'll just fail.
    if (!accessToken) {
      return;
    }

    Promise.all([
      fetchJson<Policy[]>(policiesEndpoint, fetchOptions),
      fetchJson<PolicyAgreement[]>(policyAgreementsOutdatedEndpoint, fetchOptions),
    ]).then(results => {
      const [fetchedPolicies, fetchedAgreements] = results;

      setPolicies(fetchedPolicies);
      setOutdatedAgreements(fetchedAgreements);
      setLoaded(true);
    }, (reason) => {
      console.error("Failed to display page", "Error while fetching policies and policy agreements", reason);
      setErrored(true);
    });
  }, [accessToken, policiesEndpoint, policyAgreementsOutdatedEndpoint, fetchOptions]);

  let body: ReactNode;

  if (errored) {
    body = <PolicyAgreementAppError/>;
  }
  else if (!loaded) {
    body = <PolicyAgreementAppLoading/>;
  }
  else if (canceled) {
    body = <PolicyAgreementAppCanceled/>;
  }
  else {
    body = <PolicyAgreementForm
             appId={appId || ''}
             location={location}
             destination={destination || ''}
             policyAgreements={outdatedAgreements}
             policies={policies}
             onSubmit={handleSubmit}
             onCancel={handleCancel}
           />
  }

  return (
      <PolicyAgreementErrorBoundary>
        {body}
      </PolicyAgreementErrorBoundary>
  );
};

export default PolicyAgreementApp;
