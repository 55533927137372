import { useCallback, useState } from "react";
import {
  AccessToken,
  IDToken,
  OktaAuth,
  ParseFromUrlOptions, Token,
  TokenParams
} from '@okta/okta-auth-js';
import { unstable_batchedUpdates } from "react-dom";

/**
 * Okta access token object.
 */
export const OidcAccessToken = {
  STORAGE_KEY: 'accessToken'
};

export const OidcIdToken = {
  STORAGE_KEY: 'idToken'
};

export interface OidcTokens {
  idToken: IDToken | null;
  accessToken: AccessToken | null;
}

export enum AuthFlowState {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED"
}

export interface UseAuthTokensOptions {
  accessToken?: boolean;
  idToken?: boolean;
  skip: boolean;
  parseFromUrlOptions: ParseFromUrlOptions;
  getWithRedirectOptions: TokenParams;
}

export interface UseAuthTokensReturnValue {
  tokens: OidcTokens;
  oidcState: string | undefined;
  authFlowState: AuthFlowState;
}

const defaultOptions: Partial<UseAuthTokensOptions> = {
  accessToken: true,
  idToken: true,
  skip: false,
  parseFromUrlOptions: {},
  getWithRedirectOptions: {},
};

const MAX_RETRIES = 5;

/**
 * Use authentication tokens (ID and access). Initiates the OIDC login
 * flow if the tokens don't already exist in the token manager.
 *
 * @param {OktaAuth} authClient Okta authentication client
 * @param {Location} location Browser location global
 * @param {UseAuthTokensOptions} options to customize which auth tokens are fetched
 * @returns {UseAuthTokensReturnValue}
 *   Object with tokens, instances or null, and also OIDC state parameter value
 */
function useAuthTokens(
  authClient: OktaAuth,
  location?: Location,
  options: Partial<UseAuthTokensOptions> = defaultOptions
): UseAuthTokensReturnValue {
  const [oidcState, setOidcState] = useState<string>();
  const [tokens, setTokens] = useState<OidcTokens>({ idToken: null, accessToken: null });
  const [retries, setRetries] = useState<number>(0);
  const finalOptions: Partial<UseAuthTokensOptions> = {
    ...defaultOptions,
    ...options
  };

  const [authFlowState, setAuthFlowState] = useState<AuthFlowState>(
    finalOptions.skip ? AuthFlowState.COMPLETED : AuthFlowState.IN_PROGRESS
  );

  const storeTokens = useCallback((tokensToStore: [AccessToken?, IDToken?]) => {
    const tokenMap: OidcTokens = { idToken: null, accessToken: null };

    tokensToStore.forEach(tokenToStore => {
      if (!tokenToStore) {
        return;
      }

      if ("idToken" in tokenToStore) {
        tokenMap.idToken = tokenToStore;
        authClient.tokenManager.add(OidcIdToken.STORAGE_KEY, tokenMap.idToken);
      }

      if ("accessToken" in tokenToStore) {
        tokenMap.accessToken = tokenToStore;
        authClient.tokenManager.add(OidcAccessToken.STORAGE_KEY, tokenMap.accessToken);
      }
    });

    unstable_batchedUpdates(() => {
      setAuthFlowState(AuthFlowState.COMPLETED);
      setTokens(tokenMap);
    });
  }, [authClient]);

  const handleRejection = useCallback((reason: any) => {
    console.warn("Authentication failed", reason);

    // Bad tokens or no session or something went wrong, so start fresh.
    authClient.tokenManager.clear();

    // Updating retries changes state which will cause a rerender which will
    // force another attempt to pull tokens from the token manager and
    // trigger authentication flow if unsuccessful.
    setRetries(prevRetries => prevRetries + 1);
  }, [authClient.tokenManager]);

  const tokenPromises: Promise<Token | undefined>[] = [];

  if (!finalOptions.skip && finalOptions.idToken && !tokens.idToken) {
    tokenPromises.push(authClient.tokenManager.get(OidcIdToken.STORAGE_KEY));
  }

  if (!finalOptions.skip && finalOptions.accessToken && !tokens.accessToken) {
    tokenPromises.push(authClient.tokenManager.get(OidcAccessToken.STORAGE_KEY));
  }

  if (tokenPromises.length) {
    Promise.all(tokenPromises).then(promisedTokens => {
      const validTokens = promisedTokens.filter(t => !!t);

      // All retrieved tokens were valid.
      // Update component state and do nothing else.
      if (validTokens.length === promisedTokens.length) {
        storeTokens([validTokens[0] as AccessToken, validTokens[1] as IDToken]);
        return;
      }

      if (location?.hash) {
        authClient.token.parseFromUrl(finalOptions.parseFromUrlOptions)
          .then((result) => {
            setOidcState(result.state);
            storeTokens([result.tokens.accessToken, result.tokens.idToken]);
          })
          .catch(handleRejection);
      }
      else {
        authClient.token.getWithRedirect(finalOptions.getWithRedirectOptions);
      }
    }).catch(handleRejection);
  }

  if (retries >= MAX_RETRIES && authFlowState !== AuthFlowState.FAILED) {
    setAuthFlowState(AuthFlowState.FAILED);
  }

  return { tokens, oidcState, authFlowState };
}

export default useAuthTokens;
