import React, { useMemo } from 'react';
import PolicyAgreementApp from "./PolicyAgreementApp";
import useAuthTokens from "./useAuthTokens";
import UserContext from "./UserContext";
import { OktaAuth } from '@okta/okta-auth-js';
import { EnvConstants, EnvContext } from "./EnvConstants";

/**
 * Root application component props.
 */
interface AppProps {
  env: EnvConstants;
  authClient: OktaAuth;
  location: Location;
  userLangcode: string;
  history: History;
}

/**
 * Root application component.
 *
 * @param {AppProps} props Component props
 * @returns {React.ReactNode} Component virtual node instance
 */
function App(props: AppProps) {
  const { env, location, userLangcode, authClient, history } = props;

  const paramsMap = new URLSearchParams(location.search);
  let destination = paramsMap.get('destination');
  // Ensure a blank app ID if not set. There will be no app ID if the user
  // is signing in to the application dashboard.
  let appId = paramsMap.get('appId') || '';
  const oktaStateToken = paramsMap.get('token');
  let authToken: string | null = null;

  const userContextValue = useMemo(() => ({userLangcode}), [userLangcode]);

  const { tokens, oidcState, authFlowState } = useAuthTokens(authClient, location, {
    // If we have an Okta token, skip, as the token will be used for auth.
    // If we don't have a destination, then skip, as we need a redirect_uri
    // for OIDC.
    // But don't skip if we have a location hash, because then we're going
    // OIDC authentication flow.
    skip: (!!oktaStateToken || !destination) && !location.hash,
    parseFromUrlOptions: {
      responseMode: "fragment",
    },
    getWithRedirectOptions: {
      responseMode: "fragment",
      state: JSON.stringify({ destination, appId }),
      scopes: [
        'openid',
        'profile',
        'email',
        'entitlements',
        'com.gfs:aup.policies.read',
        'com.gfs:aup.policyAgreements.read',
        'com.gfs:aup.policyAgreements.write',
      ]
    },
  });

  // If we have OIDC state, then get appId and destination from that state.
  if (oidcState) {
    const state = JSON.parse(oidcState);

    destination = destination ?? state.destination;
    appId = (appId ?? state.appId) || '';

    const url = new URL(location.href);

    url.search = new URLSearchParams({
      destination,
      appId
    } as Record<string, string>).toString();

    history.replaceState(null, '', url.toString());
  }

  if (oktaStateToken) {
    authToken = oktaStateToken;
  }
  else if (tokens.accessToken) {
    authToken = tokens.accessToken.accessToken;
  }

  return (
    <EnvContext.Provider value={env}>
      <UserContext.Provider value={userContextValue}>
        <PolicyAgreementApp
          authClient={authClient}
          authFlowState={authFlowState}
          location={location}
          accessToken={authToken}
          appId={appId}
          destination={destination}
          ssoEndpoint={env.ssoEndpoint}
          policiesEndpoint="api/v1/policies"
          policyAgreementsEndpoint={`api/v1/policy-agreements/user/me`}
          policyAgreementsOutdatedEndpoint={`api/v1/policy-agreements/user/me/outdated`}
        />
      </UserContext.Provider>
    </EnvContext.Provider>
  );
}

export default App;
