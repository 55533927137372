import React from 'react';

/**
 * Accordion item props.
 */
interface AccordionItemProps {
  headerComponent?: React.ElementType;
  panelComponent?: React.ElementType;
  expanded: boolean;
  itemId: string;
  title: string;
  onHeaderClick?: (itemId: string, e: React.MouseEvent) => void;
}

/**
 * Single accordion item with clickable header and collapsible panel.
 *
 * @param {React.PropsWithChildren<AccordionItemProps>} props Component props
 * @returns {React.ReactNode} Component virtual node instance
 */
function AccordionItem(props: React.PropsWithChildren<AccordionItemProps>) {
  const {
    headerComponent: HeaderComponent = 'h3',
    panelComponent: PanelComponent = 'div',
    expanded,
    itemId,
    title,
    onHeaderClick,
    children
  } = props;

  const buttonId = `accordion-item-${itemId}-button`;
  const panelId = `accordion-item-${itemId}-panel`;

  return (
      <div className="accordion-item" data-testid={`accordion-item-${itemId}`}>
        <HeaderComponent className="accordion-header" role="heading">
          <button
              id={buttonId}
              data-testid={buttonId}
              className="accordion-trigger"
              aria-expanded={expanded}
              aria-controls={panelId}
              onClick={(e) => onHeaderClick?.(itemId, e)}
          >
            <span className="accordion-title">
              {title}
              <span className="accordion-icon" />
            </span>
          </button>
        </HeaderComponent>
        <PanelComponent
            id={panelId}
            data-testid={panelId}
            className="accordion-panel"
            role="region"
            aria-labelledby={buttonId}
            aria-hidden={!expanded}
        >
          {children}
        </PanelComponent>
      </div>
  );
}

export default AccordionItem;
