/**
 * Compiled regular expression to validate a string is a JWT.
 */
export const jwtRegex = new RegExp(/^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/);

/**
 * Tests if a string looks like a JWT.
 *
 * @param {string} token String to test
 * @returns {boolean} true if string looks like a JWT, false otherwise.
 */
function isTokenJwt(token: string) {
  return jwtRegex.test(token);
}

export default isTokenJwt;