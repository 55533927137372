import {createContext} from "react";
import AuthClientConfig from "./AuthClientConfig";

export const LINK_GFSCA = 'LINK_GFSCA';
export const LINK_GFSCOM = 'LINK_GFSCOM';
export const LINK_SUPPLIER = 'LINK_SUPPLIER';

/**
 * Constants needed per environment.
 */
export interface EnvConstants {
  selfHostname: string;
  ssoEndpoint: string;
  authClient: AuthClientConfig;
  links: { [key: string]: string };
}

const NONPRD = {
  links: {
    [LINK_GFSCA]: 'https://sit.gfs.ca/en-ca/',
    [LINK_GFSCOM]: 'https://sit.gfs.com/en-us/',
    [LINK_SUPPLIER]: 'https://suppliersit.gfs.com/',
  }
};

const LCL: EnvConstants = {
  ...NONPRD,
  selfHostname: 'localhost',
  ssoEndpoint: 'https://sso-dev.gfs.com',
  authClient: {
    issuer: 'https://sso-dev.gfs.com/oauth2/default',
    clientId: '0oaoqyd0q9wHtWiDb0h7',
  }
};

const DEV: EnvConstants = {
  ...NONPRD,
  selfHostname: 'aup-dev.gfs.com',
  ssoEndpoint: 'https://sso-dev.gfs.com',
  authClient: {
    issuer: 'https://sso-dev.gfs.com/oauth2/default',
    clientId: '0oapv2pkbbLwZhPvN0h7',
  }
};

const TST: EnvConstants = {
  ...NONPRD,
  selfHostname: 'aup-tst.gfs.com',
  ssoEndpoint: 'https://sso-staging.gfs.com',
  authClient: {
    issuer: 'https://sso-staging.gfs.com/oauth2/ausou8em77mnb3so50h7',
    clientId: '0oa19perenrolUfgW0h8',
  }
};

const SIT: EnvConstants = {
  ...NONPRD,
  selfHostname: 'aup-sit.gfs.com',
  ssoEndpoint: 'https://sso-staging.gfs.com',
  authClient: {
    issuer: 'https://sso-staging.gfs.com/oauth2/ausou8gex85ZahlAN0h7',
    clientId: '0oaokwrb5yPuHd5hV0h7',
  }
};

const PRD: EnvConstants = {
  selfHostname: 'aup.gfs.com',
  ssoEndpoint: 'https://sso.gfs.com',
  authClient: {
    issuer: 'https://sso.gfs.com/oauth2/aus262s5e2fsv2GHy357',
    clientId: '0oa2wqpp8stiWydE8357',
  },
  links: {
    [LINK_GFSCA]: 'https://www.gfs.ca/en-ca/sign-in',
    [LINK_GFSCOM]: 'https://www.gfs.com/en-us/sign-in',
    [LINK_SUPPLIER]: 'https://supplier.gfs.com/',
  }
};

/**
 * Gets the environment constants by hostname.
 *
 * @param {string} hostname Current hostname
 * @return {EnvConstants} Current environment constants
 */
export const getEnvConstants = (hostname: string) => {
  for (const env of [LCL, DEV, TST, SIT, PRD]) {
    if (env.selfHostname === hostname) {
      return env;
    }
  }

  return LCL;
};

/**
 * Environment context.
 */
export const EnvContext = createContext(LCL);
