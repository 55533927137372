/**
 * Helper method to wrap native fetch() with JSON defaults. Also provides simple
 * default error handling.
 *
 * @param {RequestInfo} input input to fetch
 * @param {RequestInit} init init for fetch
 * @returns {Promise<Response>} promise for the fetch
 */
async function fetchJson<T>(input: RequestInfo, init: RequestInit = {}) {
  const response = await fetch(input, Object.assign({
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json',
    }
  }, init));

  if (response.ok) {
    return response.json() as Promise<T>;
  }

  throw new Error(`Failed to fetch '${input}' because '${response.statusText}'`);
}

export default fetchJson;
